.access-denied {

  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("/assets/access-denied.jpg");
  background-position: center;
  background-repeat: no-repeat;
  color: white;

  .veil {
    background-color: rgba(0,0,0,0.8);
    height: inherit;
    width: inherit;
  }
}
