@import "src/components/styles/variables";

.invitations-confirmation {
  .centerAlignWork {
    display: flex;
    margin: 20px 0;
    align-items: center;
    flex-direction: column;
  }

  .company {
    width: 50%;
  }

  .roomHeadDone {
    margin-top: 20px;
    font-size: larger;
    font-weight: bolder;
  }

  .createRoomBox4 {
    width: 45%;
    padding: 2em;
    display: flex;
    margin-top: 10px;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    box-shadow: 0 0 2px gray;
    background-color: #e9e9e9;
  }

  .tickLogo {
    width: 150px;
  }

  .greenBoldTxt {
    color: #07a79d;
    margin-top: 20px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }

  .circleOuter {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    justify-content: center;
    background-color: white;
  }

  .circleInner {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 100px;
    justify-content: center;
    background-color: white;
    box-shadow: 0 1px 5px silver;
  }

  @media only screen and (max-width: 600px) {
    .tickLogo {
      width: 100px;
    }

    .greenBoldTxt {
      color: #07a79d;
      margin-top: 20px;
      font-size: medium;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }
    .circleOuter {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      border-radius: 100px;
      justify-content: center;
      background-color: white;
    }
    .circleInner {
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      border-radius: 100px;
      justify-content: center;
      background-color: white;
      box-shadow: 0px 1px 5px silver;
    }
    .create4RoomFoot{
      justify-content: center;
    }
  }

}