/* Scrollbar */
div {
  &::-webkit-scrollbar {
    background: 0 0;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: 0 0;
  }

  &::-webkit-scrollbar-track-piece {
    background: 0 0;
  }

}


@mixin settings-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #3d3e3f;
  background-position: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: hsla(180, 0%, 50%, 0.25);
    pointer-events: none;
  }

  .modal-content {
    background-color: unset !important;
  }
}


@each $roomtype in breakroom, office, openspace, meetingroom, conference, forum, lobby {
  //noinspection CssReplaceWithShorthandSafely
  .bg-#{$roomtype} {
    background: linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
    ), url("/assets/roombg/#{$roomtype}.png");
    @include settings-bg;
  }
}

.bg-user-profile {
  background: linear-gradient(
                  rgba(0, 0, 0, 0.7),
                  rgba(0, 0, 0, 0.7)
  ), url("/assets/profilebg/user-profile.jpg");
  @include settings-bg;
}