.search-rooms {

  .search-bar {
    .centerView {
      flex-direction: column;
      justify-content: center;
    }

    .searchWidthControll {
      width: 50%;
      box-shadow: 0 1px 5px gainsboro;
    }

    .dropDownRows {
      width: 30%;
      display: flex;
      margin: 20px 0;
      flex-direction: row;
      justify-content: space-around;
    }

    .dropStyle {
      width: 120px;
      color: white;
      border-radius: 20px;
      background-color: black;
    }

    @media only screen and (max-width: 600px) {
      .searchWidthControll {
        width: 90%;
        box-shadow: 0 1px 5px gainsboro;
      }
    }

  }

  .room-box {
    display: flex;
    margin: 2em 0;
    flex-direction: row;
    background-color: #f8f6f6;
    box-shadow: 0 2px 2px gainsboro;

    .circles {
      width: 130px;
      height: 130px;
      margin: 0 20px;
      border-radius: 100px;
    }

    .viewContent {
      width: 50%;
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .address, .owner {
      color: gray;
      font-size: 18px;
    }

    .owner {
      float: right;
      font-weight: bold;
    }

    .squares {
      object-fit: cover;
      margin: 0 20px;
    }

    .viewContent1 {
      width: 50%;
      display: flex;
      flex-direction: row;
    }

    .vhcenter {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .enterButStyle {
      padding: 5px 20px;
      font-size: larger;
      background-color: rgb(3, 148, 148);
    }

    @media only screen and (max-width: 600px) {
      .containers {
        display: flex;
        margin: 2em 0;
        flex-direction: column;
        background-color: #f8f6f6;
        box-shadow: 0 0 5px silver;
      }
      .viewContent {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .viewContent1 {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .headDiscBox {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .vhcenter {
        width: 100%;
        margin: 1em 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .circles {
        width: 100px;
        height: 100px;
        margin: 0 20px;
        border-radius: 100px;
      }
      .roomname {
        font-size: medium;
      }
      .sitename {
        font-size: small;
      }

      .viewContentText {
        color: gray;
        font-size: 12px;
      }
    }

  }


  .footCenterView{
    display: flex;
    margin: 20px 0;
    align-items: center;
    flex-direction: column;

    .ending-text {
      font-family: robotomedium, sans-serif;
      color: #8F8F8F;
      font-size: 25px;
    }
  }
}