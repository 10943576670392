@import "../../styles/variables";

.navbar {

  z-index: 2;

  #user-dropdown {

    min-width: 200px;

    .profile-img {
      height: 40px;
    }
  }
}

.welcome {

  .navbar {

    z-index: 2;

    .logo{
      height: 50px;
    }

    .bgColor {
      background-color: black !important;
    }

    .alignment {
      padding-right: 5%;
      justify-content: flex-end;
    }

    .colorLink {
      color: white !important;
    }

    .buttonStyle {
      background-color: #07a79d !important;
    }

  }

  .heading {

    background-image: url("/assets/cover.png");
    position: relative;
    left: 0;
    right: 0;
    height: calc(100vh - 76px);

    .vail {
      background-color: black;
      opacity: 0.7;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    .cover-container {
      max-width: 42em;
      position: relative;
      z-index: 2;
    }

    .cover {
      width: 100%;
      filter: brightness(0.23);
    }

    .coverText {
      color: white;
      letter-spacing: 2px;
      font-size: xx-large;
      font-family: sans-serif;
    }

    .coverInputWidth {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
    }

    .coverBottomTxt {
      position: absolute;
      top: 100%;
      left: 90%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: medium;
      font-family: Arial, Helvetica, sans-serif;
    }

    .largeSearchBox {

      input {
        height: 48px;
        font-size: 1.25rem;
      }
    }
    .inputsBox {
      position: absolute;
      display: flex;
      cursor: pointer;
      border-radius: 5px;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      z-index: 1;

      a {
        text-decoration: none;
      }

      .inputsBoxText {
        color: black;
        font-weight: 500;
        margin: 10px 20px;
      }

      .greenTextBold {
        color: #07a79d;
        font-weight: 500;
      }

      .smallLogo {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        border: 2px solid #07a79d;
      }

      .inputsBoxdivrow {
        display: flex;
        padding: 10px;
        align-items: center;
      }

      .inputsBoxdivRowLine {
        width: 100%;
        height: 50px;
        display: flex;
        margin: 0.2em 0;
        padding-right: 10px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
      }

      .verticalColumn {
        display: flex;
        margin-left: 20px;
        flex-direction: column;
      }

      .smallTextBold {
        font-size: 12px;
      }

      .registerBox {
        display: flex;
        padding: 1em 0 2em 0;
        justify-content: center;
      }

      .bolderTxt {
        font-weight: bold;
      }

      .plus {
        width: 20px;
        margin: 3px;
        height: 20px;
      }

    }


  }

  .dropdown-item.active {
    background-color: #03a79d26;
  }
}