@import "src/components/styles/variables";

.boarding {

  .navbar {
    .logo{
      height: 50px;
    }

    .bgColor {
      background-color: black !important;
    }

    .alignment {
      padding-right: 5%;
      justify-content: flex-end;
    }

    .colorLink {
      color: white !important;
    }

    .buttonStyle {
      background-color: #07a79d !important;
    }
  }

  .step-box {
    width: 60%;
    display: flex;
    padding: 5em;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    box-shadow: 0 0 2px gray;
    background-color: #e9e9e9;
  }

  > .segment {
    height: 100%;
    width: 100%;
    padding: 0;

    > .column {
      height: 100%;

      .pane {
        height: 100%;
    
        &.left {
          display:flex;
          align-items: center;
          justify-content: center;
          background-color: $color-primary;
          color: $color-secondary;

          .header {
            color: $color-secondary;
            font-size: xx-large;
            padding: 20px;
          }
        }

        &.right {

          text-align: justify;
          .bottom-bar {
            box-shadow: 0px -8px 4px -4px rgba(0, 0, 0, 0.47);
          }
        }
      }
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
  }

  .company-creation {

    .centerAlignWork {
      display: flex;
      margin: 20px 0;
      align-items: center;
      flex-direction: column;
    }

    .company {
      width: 50%;
    }

    .roomHead {
      margin-top: 20px;
      font-size: larger;
      font-weight: bolder;
      font-family: Arial, Helvetica, sans-serif;
    }

    .createRoomBox1 {
      width: 60%;
      display: flex;
      padding: 5em 0;
      align-items: center;
      border-radius: 20px;
      flex-direction: column;
      box-shadow: 0 0 2px gray;
      background-color: #e9e9e9;
    }

    .createRoomInputs {
      width: 60%;
      margin: 10px 0;
      box-shadow: 0 2px 2px gainsboro;
    }

    .logoText {
      width: 70px;
      height: 50px;
    }

    #imagepicker {
      display: none;
    }

    .alignInRow {
      display: flex;
      flex-direction: row;
    }

    .createRoomFoot {
      width: 60%;
      display: flex;
      justify-content: flex-end;
    }

    .showCursor {
      cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
      .roomHead {
        font-size: small !important;
      }
      .company {
        width: 100% !important;
      }
      .createRoomBox1 {
        display: flex;
        margin-top: 1em;
        padding: 2em 0 !important;
        align-items: center;
        border-radius: 20px;
        width: 100% !important;
        flex-direction: column;
        box-shadow: 0 0 2px gray;
        background-color: #e9e9e9;
      }
      .createRoomInputs {
        margin: 0.5em 0 !important;
        box-shadow: 0 2px 2px gainsboro;
      }
      .alignInRow {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }


  }

  .company-site-creation {
    .centerAlignWork {
      display: flex;
      margin: 20px 0;
      align-items: center;
      flex-direction: column;
    }

    .company {
      width: 50%;
    }

    .roomHead {
      margin-top: 20px;
      font-size: larger;
      font-weight: bolder;
      font-family: Arial, Helvetica, sans-serif;
    }

    .createRoomInputs {
      margin: 10px 0;
      box-shadow: 0 2px 2px gainsboro;
    }

    .createRoomFoot {
      width: 60%;
      display: flex;
      justify-content: flex-end;
    }

    .inputsInRow {
      width: 60%;
      display: flex;
      margin: 10px 0;
      flex-direction: row;
      justify-content: space-between;
    }

    .smallWidth {
      width: 30%;
    }

    .mediumWidth {
      width: 67%;
    }

    @media only screen and (max-width: 600px) {
      .inputsInRow {
        width: 80%;
        display: flex;
        margin: 10px 0;
        flex-direction: row;
        justify-content: space-between;
      }
      .createRoomFoot {
        width: 80%;
        display: flex;
        justify-content: flex-end;
      }
    }

  }

  .room-creation {

    .centerAlignWork {
      display: flex;
      margin: 20px 0;
      align-items: center;
      flex-direction: column;
    }

    .company {
      width: 50%;
    }

    .roomHead {
      margin-top: 20px;
      font-size: larger;
      font-weight: bolder;
      font-family: Arial, Helvetica, sans-serif;
    }

    .createRoomBox3 {
      width: 45%;
      padding: 2em;
      display: flex;
      align-items: center;
      border-radius: 20px;
      flex-direction: column;
      box-shadow: 0 0 2px gray;
      background-color: #e9e9e9;
    }

    .room3Row {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    .boldText {
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }

    .RoomInputs {
      width: 30%;
      margin: 10px 0;
      box-shadow: 0 2px 2px gainsboro;
    }

    .longRoomInputsWrapper {
      width: 100%;
    }

    .longRoomInputs {
      width: 100%;
      margin-bottom: 10px;
      box-shadow: 0 2px 2px gainsboro;
    }

    .create3RoomFoot {
      margin-top: 20px;
    }

    @media only screen and (max-width: 600px) {
      .createRoomBox3 {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 20px;
        flex-direction: column;
        padding: 1em !important;
        margin-top: 1em !important;
        box-shadow: 0 0 2px gray;
        background-color: #e9e9e9;
      }
    }

    .choices {
      flex-grow: 1;
      overflow-y: auto;
      text-align: center;
      color: $color-secondary;

      > .grid {

        position: absolute;

        .room-type {

          cursor: pointer;
          padding: 2px;
          
          > .ribbon {
            position: absolute;
            left: -10px;
            top: 8px;
            z-index: 3;
          }

          > .checked {
            bottom: 5px;
            left: 130px;
            top: unset;
          }

          &:hover:not(.inactive) img {
            background-color: $color-primary;
            opacity: 0.4;
          }
          
  
          &.inactive  {
            
            * {
              cursor: not-allowed;
            }
  
            img {
              background-color: black;
              opacity: 0.3; 
            }
          }
  
          .selected {
            background-color: rgba($color: $color-tiers, $alpha: .4);
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
  
            .icon {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              font-size: 34pt;
              color: white;
              display: block;
              margin: auto;
            }
          }
        }
      }

      h3 {
        position: absolute;
        top: 40%;
        bottom: 60%;
        left: 0;
        right: 0;
        margin: auto;
        font-size: xx-large;
        font-weight: 700;
      }
    }
  }

  .room-configuration {
    flex-direction: column;
    height: 100%;
    
    .config-form {
      flex-grow: 1;
      overflow-y: auto;

      h3 {

        text-align: left;
      }
    }
  }

  .invitations {

    .centerAlignWork {
      display: flex;
      margin: 20px 0;
      align-items: center;
      flex-direction: column;
    }

    .company {
      width: 50%;
    }

    .roomHead {
      margin-top: 20px;
      font-size: larger;
      font-weight: bolder;
      font-family: Arial, Helvetica, sans-serif;
    }

    .createRoomBox4 {
      width: 45%;
      padding: 5em;
      display: flex;
      margin-top: 10px;
      align-items: center;
      border-radius: 20px;
      flex-direction: column;
      box-shadow: 0 0 2px gray;
      background-color: #e9e9e9;
    }

    .inputsInRow4 {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }

    .invitation-field {
      margin: 2px;
      box-shadow: 0 1px 2px silver;
    }

    .lastColumn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .addInput {
      width: 80px;
      height: 30px;
      display: flex;
      margin-top: 5px;
      align-items: center;
      border-radius: 20px;
      justify-content: center;
      background-color: white;
      border: 1px solid gainsboro;
    }

    .plusIcon {
      font-size: 20px;
      color: gainsboro;
    }

    .create4RoomFoot {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;
    }

    .skipTxt {
      color: gray;
      display: flex;
      margin-right: 20px;
      align-items: center;
      justify-content: center;
    }

    @media only screen and (max-width: 600px) {
      .createRoomBox4 {
        padding: 5em;
        display: flex;
        margin-top: 10px;
        align-items: center;
        border-radius: 20px;
        flex-direction: column;
        width: 100% !important;
        box-shadow: 0 0 2px gray;
        background-color: #e9e9e9;
      }
    }

  }
}