#hgr-home {

  .room-view-content {

    z-index: 4;
    right: 0;
    width: 100%;
    display: flex;
    position: fixed;
    flex-direction: column;

    .hgr-header {

      z-index: 5;
      display: flex;
      position: absolute;
      align-self: flex-end;
      justify-content: flex-end;
      right: 240px;
      left: 10px;
      top: 30px;

      .room-actions, .company-actions {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
    }
  }

  .hgr-icon {
    height: 24px;
    width: 24px;
    align-self: center;
  }

  .hgr-icon-circle {
    background-color: #fff;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    border: 1px solid #d1dbe8;
    margin: 5px;
    justify-content: center;
    cursor: pointer;
    padding: 5px;

    &:hover {
      background-color: #daebfa;
      border: 1px solid #daebfa;
    }

    &.toggled {
      background: #2a3a4b;
      border: 1px solid #5e6d7a;

      svg {
        fill: #fff;
      }

      &:hover {
        background-color: #5e6d7a;
      }
    }

    &.disabled, .disabled & {
      cursor: initial;
      color: #fff;
      background-color: #a4b8d1;
    }

    svg {
      fill: #5e6d7a;
    }

    .icon {
      height: 100%;
      width: 100%;
    }
  }

  .loader-parent {
    position: absolute;
    z-index: 6;
  }

  .modal {

    .modal-content {
      height: 100%;
    }
  }

}