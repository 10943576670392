.modal {

  &.dark {

    .modal-content {
      background-color: $dark;
      color: $light;

      .modal-header {
        border-bottom: 0;
      }

      .modal-footer {
        border-top: 0;
      }

      .form-control-plaintext {
        color: $light;
      }

      .form-control {
        color: $light;
        background-color: transparent;


      }
    }
  }
}