.notification-panel {

  font-family: robotoregular, sans-serif;
  position: relative;
  width: 40px;
  min-height: 40px;
  max-height: 20em;
  display: flex;
  padding: 8px;
  overflow: hidden;
  cursor: default;
  flex-direction: column;
  background: #C7C7C7 0 0 no-repeat padding-box;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 20px;
  transition: width .5s ease;

  .hgr-icon {
    &.active {
      color: #00B5AD !important;
    }
  }

  &:not(.opened) {
    .notifyPanelText {
      visibility: hidden;
      height: 0;
      width: 0;
      padding: 0;
    }
    .notifications {
      height: 0;
      width: 0;
    }
  }

  .notifyPanelText {
    text-align: left;
    font-size: 1.3em;
    padding-left: 25px;
    letter-spacing: 0;
    opacity: 1;
  }

  &.opened {
    width: 17em;
  }

  .notification-panel-header {
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .notifications {
    overflow: auto;
    flex-grow: 1;
    overflow-x: hidden;

    .Toastify__toast-container {
      z-index: unset;
      position: unset;
      width: unset;

      .Toastify__toast {
        background: unset;
        color: black;
        box-shadow: unset;
      }
    }

  }

  .notification {
    width: 34px;
    height: 40px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 100px;
  }

  .userNotifyView {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 5px 5px 10px;
  }

  .avatarLogo {
    width: 30px;
    height: 30px;
    border-radius: 100px;
  }

  .notifyTtx {
    font-size: 0.8em;
    font-weight: 500;
  }

  .userNotifyColumn {
    display: flex;
    justify-content: space-around;

    button {
      margin: 3px;
    }
  }

  .notification-actions {

    button {
      margin: 4px;
    }

    .confirmation {
      outline: none;
      color: white;
      font-size: 10px;
      border-radius: 2px;
      background-color: #07a79d;
      border: 1px solid #07a79d;
    }

    .reject {
      color: gray;
      outline: none;
      font-size: 10px;
      border-radius: 2px;
      background-color: gainsboro;
      border: 1px solid gainsboro;
    }

  }

  .userRowBox {
    margin-left: 10px;
  }

}