@import "components/styles/variables";

#root {
  height: 100%;
  width: 100%;
}

.video-loader {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: auto;
  z-index: 30;
  background: black linear-gradient(black, white, black);
  display: flex;

  > video {
    object-fit: cover;
  }

  .veil {
    z-index: 10;
    background-color: rgba($color: $color-tiers, $alpha: 0.4);
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.lds-dual-ring {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}


.hgr-hidden {
  display: none!important;
}


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.in-app {

  .modal-content {
    height: 100%;
    border: 0;

    .modal-header {
      height: 20vh;
      max-height: 150px;
      border-bottom: 0;
    }

    .modal-body {
      overflow: auto;
      flex-grow: 1;
    }
  }

  .settings, &.settings {

    width: 1150px;
    max-width: 95%;
    height: 90%;
    max-height: none!important;
    min-height: unset;

    .roomContainerBox {
      width: 100%;
      height: 100%;
      padding: 10px;
      display: flex;
      flex-direction: column;

      .owner-line {
        justify-content: space-between;
        display: flex;
      }
    }

    .modal-header {

      justify-content: center;
      align-items: center;

      .roomContainerHeading {
        font-size: 34px;
        color: #07a79d;
        font: bold 50px/60px robotoregular;
      }
    }

    .options {
      color: white;
      font-size: x-large;
    }

    .optionsRow {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
    }

  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .mobile-hidden {
    display: none !important;
  }
}
