
.room-settings {

    .modal-content {
        background-color: black;
    }

    .roomContainerBox {

        .owner-line {
            justify-content: space-between;
            display: flex;
        }

        .occupants, .invitations {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .invitation-form {
                padding: 5px 0;
            }

            .userAvatar {
                width: 20px;
                height: 100%;
                margin-right: 10px;
                border-radius: 100px;
            }

        }
    }

    .modal-header {

        justify-content: center;
        align-items: center;

        .roomContainerHeading {
            font-size: 34px;
            color: #07a79d;
            font: bold 50px/60px robotoregular;
        }
    }

    hr {
        color: white;
        height: 1px;
        width: inherit;
    }
    .options {
        color: white;
        font-size: x-large;
    }

    .optionsRow {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
    }

    .room-name {
        outline: none;
        text-align: center;
        color: white;
        margin-top: 10px;
        font-size: x-large;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: rgba(0,0,0,.1);
        border: 1px solid #ffffff;
    }

}