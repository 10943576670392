.homeRow {
  flex-direction: row;
}

.stepView {
  width: 100%;
  padding: 20px 50px;
  justify-content: flex-start;
}

.step2View {
  width: 100%;
  display: flex;
  padding: 0 50px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
}

.box {
  width: 50%;
}

.step3View {
  width: 100%;
  padding: 0 50px;
  justify-content: flex-start;
}

.stepsStyle {
  text-align: left;
  color: rgb(3, 148, 148);
  font-family: Arial, Helvetica, sans-serif;
}

.butStyle {
  padding: 5px 50px !important;
  background-color: #07a79d !important;
  border: 1px solid #07a79d !important;
}

.centerView {
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .stepsStyle {
    text-align: left;
    font-size: medium;
    font-weight: bolder;
    color: rgb(3, 148, 148);
    font-family: Arial, Helvetica, sans-serif;
  }
  .text {
    width: 100%;
    font-size: small;
  }
  .step2View {
    width: 100%;
    display: flex;
    padding: 0 50px;
    flex-direction: column;
  }
  .para {
    width: 100%;
    font-size: small;
  }
  .box {
    width: 100%;
  }
}
