$font-family-sans-serif:  robotoregular, sans-serif;

$dark: #2d2d2d;
$light: #D4D4D4;
$secondary: #535353;

$theme-colors: (primary: #03a79d);

$headings-color: #03a79d;

@import "node_modules/bootstrap/scss/bootstrap";

@import "scss/theme";
@import "scss/buttons";
@import "scss/modal";
