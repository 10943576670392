@import 'src/components/styles/variables';


.navigation-pane {

  position: absolute;
  z-index: 5;
  height: 90vh;
  left: -280px;
  transition: left .5s ease-in-out;

  &.opened {
    left: 0;

    .home-button {
      box-shadow: 0 0 2px 3px white;
    }

  }

  .home-button {
    position: absolute;
    right: -20px;
    top: 30px;
  }

  .navigation-zone {

    width: 300px;
    padding: 10px;
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 1px 1px 5px #1a1a1a;
    height: 100%;

    > .navigation-pane-content {

      display: flex;
      height: 100%;
      padding: 0 5px;
      align-items: center;
      flex-direction: column;

      .company-info {

        display: flex;
        flex-direction: column;
        align-items: center;

        .drawerLogo {
          width: 80px;
        }

        .CompanyNameButton {
          width: 85%;
          color: white;
          margin: 10px 0;
          font-size: small;
          border-radius: 20px;
          border: 1px solid slategray;
          background-color: #07a79d;
        }

        .searchRoomInput {
          width: 100%;
          padding: 5px;
          outline: none;
          margin: 10px 0;
          font-size: small;
          border-radius: 5px;
          background-color: #535353;
          border: 1px solid #535353;
        }
      }

      .rooms-container {

        display: flex;
        flex-grow: 1;
        width: 100%;
        padding: 0 5px;
        align-items: stretch;
        flex-direction: column;
        overflow-y: auto;

        &::-webkit-scrollbar {
          background: 0 0;
          width: 7px;
          height: 7px;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, .5);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background: 0 0;
        }

        &::-webkit-scrollbar-track-piece {
          background: 0 0;
        }

        .company-site {

          .subHeading {
            width: 100%;
            display: flex;
            align-items: center;
          }

          .building {
            width: 40px;
            height: 40px;
          }

          .colorWhite {
            font-size: large;
            color: silver;
          }

          .room-group {

            .heading {
              color: silver;
              margin-left: 5px;
            }

            .room-pane {

              margin: 5px 0 5px 10px;
              display: flex;
              padding: 5px 0;
              cursor: pointer;
              border-radius: 5px;
              align-items: center;
              flex-direction: column;

              .room-name {
                font-size: medium;
                color: silver;
                flex-grow: 1;
                position: relative;
                width: unset;

                .badge {
                  position: absolute;
                  top: -15px;
                  right: 10px;
                }
              }

              .room-state {
                font-size: medium;
                width: 20px;
                color: silver;
                text-align: center;
                padding-left: 5px;
                border-left: solid 1px;
                svg {
                  transition: transform 200ms ease-in-out , background-position 800ms ease-in-out, box-shadow 500ms linear;
                  transform: scale(1);
                }

                &:not(.active):hover {
                  svg {
                    transform: scale(1.3);
                  }
                }

                &.active:not(.locked) {
                  color: #0ba197;
                }

                &.locked {
                  color: #a55656;
                }
              }

              .row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
              }

              .users {
                width: 90%;
                color: white;
                font-family: "Roboto Condensed", sans-serif;

                .userAvatar {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                  border-radius: 100px;
                }
              }
            }
          }
        }
      }

    }

    .xContainer {
      width: 100%;
      display: flex;
      cursor: pointer;
      justify-content: flex-end;
    }


    .colorGreen {
      color: #0ba197;
    }

    .company-actions {

      display: flex;
      height: 50px;
      width: 100%;
      margin-bottom: -10px;

      .left-buttons {
        margin-right: 20px;
        display: flex;
        align-items: center;
      }

      .right-buttons {
        flex-grow: 1;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
      }
    }

  }

  .hgr-icon-circle.avatar {
    background-position: center;
    background-size: cover;
  }
}
