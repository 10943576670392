.image-upload {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;

  .image-edit {
    position: absolute;
    right: 20px;
    z-index: 1;
    bottom: 20px;

    button {
      width: 34px;
      height: 34px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    }

    input {
      display: none;

    }
  }
  .circles {
    width: 125px;
    height: 125px;
    margin: 20px 20px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 1);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}