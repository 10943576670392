
.btn-icon {
  border-radius: 50%;
  padding: 0.25rem 0.25rem;
  height: 34px;
  width: 34px;
}

.btn-light {
  color: $secondary;
}

.react-switch-bg {

  svg, .switch-icon {
    height: 60%;
    width: 60%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
